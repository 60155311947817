import { Component, Input } from '@angular/core';
import { Product } from '../../providers/services/data.service';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.scss'],
})
export class ProductItemComponent {
  @Input() item: Product;

  constructor(
    private navCtrl: NavController,
  ) { }

  onQuickShopClick() { }

  onProductClick() {
    this.navCtrl.navigateForward(['product-details/' + this.item.pk]);
  }
}
