import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { PortfolioService } from "../../providers/services/portfolio.service";
import { DataService } from "../../providers/services/data.service";
import { Location } from '@angular/common';
import { keywords } from "../../../assets/scents";

@Component({
  selector: 'app-story-text',
  templateUrl: './story-text.page.html',
  styleUrls: ['./story-text.page.scss'],
})
export class StoryTextPage implements OnInit {
  nextButtonEnabled: boolean = false;
  storyText: string = '';
  emotionalWords = [];
  selectedEmotion = -1;
  selectedMood = -1;
  relatedWords = []
  isEnlarged = false;
  suggestionNumber = 5;
  searchSuggestions: string[] = []
  suggestionChips: {mood:number, tags:any, scents:any}[] = []
  currentMood: number;
  possibleSuggestions: Record<string, string | number>[] = []
  selectedChips: string[] = []
  currentValue: string = ""
  isFocused: boolean = false
  isKeyword:boolean = true
  isSubmitting: boolean = false

  constructor(
    private router: Router,
  ) { }

  ngOnInit() { 
    for (let index = 0; index < keywords.length; index++) {
      let currentMood = []
      currentMood = this.merge(currentMood, keywords[index]['Mood-Related Words'])
      currentMood = this.merge(currentMood, keywords[index]['Wellness-Related Words'])
      currentMood = this.merge(currentMood, keywords[index]['Words Describing Humans'])

      this.emotionalWords.push({mood: index, value: keywords[index]['Emotional Words']})
      this.searchSuggestions = this.merge(currentMood, this.searchSuggestions)
      this.suggestionChips.push({mood: index, tags: currentMood, scents: keywords[index].Scents})
    }

    for (let index = 0; index < this.suggestionChips.length; index++) {
      this.possibleSuggestions.push({mood: this.suggestionChips[index].mood, value: this.suggestionChips[index].tags[Math.floor(Math.random() * this.suggestionChips[index].tags.length)]})
    }

  }

  merge(array1, array2) {
    return [... new Set([...array1, ...array2])];
  }


  deleteChip(value){
    this.selectedChips.splice(value,1)
  }

  selectKeyword(value){
    if (!this.selectedChips.includes(value)) {
      this.selectedChips.push(value)
      this.isFocused = false
    }
  }

  addChip(value){
    if (!this.selectedChips.includes(value.value)) {
      this.selectedChips.push(value.value)
      this.isFocused = false
      this.selectedMood = value.mood
      this.changeMood(value.mood)
    }
  }

  addEmotionChip(value){
    if (!this.selectedChips.includes(value.value)) {
      this.selectedChips.push(value.value)
      this.isFocused = false
      this.selectedEmotion = value.mood
      this.changeMood(value.mood)
    }
  }

  changeMood(mood){
    this.currentMood = mood
    let newSuggestions = []
    let target = this.selectedMood==-1 ? this.suggestionChips[mood].tags :  this.suggestionChips[mood].scents;
    let chooser = this.randomNoRepeats(target);
    for (let index = 0; index < this.suggestionNumber; index++) {
      let choosen = chooser()
      newSuggestions.push({mood, value: choosen})
      console.log(choosen)
    }
    this.possibleSuggestions = newSuggestions
  }

  randomNoRepeats(array) {
    var copy = array.slice(0);
    return function() {
      if (copy.length < 1) { copy = array.slice(0); }
      var index = Math.floor(Math.random() * copy.length);
      var item = copy[index];
      copy.splice(index, 1);
      return item;
    };
  }

  addInputChip(){
    if (this.currentValue && !this.selectedChips.includes(this.currentValue)) {
      this.selectedChips.push(this.currentValue)
      this.isFocused = false
    } 
  }

  focused(){
    this.isFocused = true
  }

  unfocused(){
    this.isFocused = false
  }

  readInput(event){
    const query = event.target.value;
    this.currentValue = query
  }

  changeToKeyword(){
    this.isKeyword = true
  }

  changeToMessage(){
    this.isKeyword = false
  }

  onStoryChange(text) {
    const wordsCount = text.trim().split(' ').length;
    this.nextButtonEnabled = (wordsCount >= 3);
  }

  enlargeSwitch(){
    this.isEnlarged = !this.isEnlarged
    this.suggestionNumber = this.isEnlarged ? 15 : 5
    this.changeMood(this.currentMood)
    this.unfocused()
  }

  getLocation() {
    // Mountain Everest as fallback location
    // Literally in the middle of nowhere lol
    const fallbackLocation = {
      latitude: 27.9881201,
      longitude: 86.9249751
    };

    return new Promise((resolve) => {
      fetch('https://ipapi.co/json/')
        .then((response) => response.json())
        .then((json) => {
          const {latitude, longitude} = json;
          resolve({ latitude, longitude });
        })
        .then(function() {
          resolve(fallbackLocation);
        })
        .catch(() => {
          resolve(fallbackLocation);
        });
    });
  }

  async openIngredientsCombination() {
    this.isSubmitting = true
    const location: any = await this.getLocation();
    localStorage.setItem('location', JSON.stringify(location));

    const story = {
      type: 'text',
      language: 'en',
      original_generated_text: this.isKeyword? this.selectedChips.toString() : this.storyText.trim(),
      personality: true
    }

    localStorage.setItem('story', JSON.stringify(story))

    this.router.navigateByUrl('/story-ingredients-prepare?loaded=true');
  }

}
