import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NavController } from '@ionic/angular';
import { Client, DataService } from '../../providers/services/data.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage implements OnInit {
  client$: Observable<Client>;

  constructor(
    private data: DataService,
    private navCtrl: NavController,
  ) { }

  ngOnInit() {
    this.client$ = this.data.client$;
  }

  getStarted(){
    this.navCtrl.navigateForward(['/chat']);
  }
}
