import { Component, Input } from '@angular/core';

export interface ChatMessage {
  text: string;
  sender: 'bot' | 'user';
}

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
})
export class ChatMessageComponent {
  @Input() message: ChatMessage;
  @Input() profileImage: string;
  @Input() showProfileImage: boolean;

  constructor() { }
}
