import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-header-blue',
  templateUrl: './header-blue.component.html',
  styleUrls: ['./header-blue.component.scss'],
})
export class HeaderBlueComponent implements OnInit {
  @Input() title: string;
  @Input() showCloseButton?: boolean = true;
  @Input() redirectTO?: string;
  @Input() showBackArrow?: boolean = false;
  constructor(public location: Location, private router: Router) {}

  ngOnInit() {}

  goBack() {
    if (this.redirectTO) {
      this.router.navigate([this.redirectTO]);
    } else {
      this.location.back();
    }
  }
}
