import { Component, Input } from '@angular/core';
import { ProductCategory } from '../../providers/services/data.service';

@Component({
  selector: 'app-product-category',
  templateUrl: './product-category.component.html',
  styleUrls: ['./product-category.component.scss'],
})
export class ProductCategoryComponent {
  @Input() category: ProductCategory;
  @Input() selected: boolean;

  constructor() { }
}
