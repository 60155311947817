import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { IonInput } from '@ionic/angular';

@Component({
  selector: 'app-message-bar',
  templateUrl: './message-bar.component.html',
  styleUrls: ['./message-bar.component.scss'],
})
export class MessageBarComponent {
  @Output() sendMessage = new EventEmitter<string>();
  @Output() typingStarted = new EventEmitter<void>();
  @Output() typingStopped = new EventEmitter<void>();

  public isFocused = false;
  public message = '';

  @ViewChild('messageInput') private messageInput!: IonInput;

  constructor() { }

  onMessageFocus() {
    if (!this.isFocused) {
      setTimeout(() => {
        this.messageInput.setFocus();
        this.isFocused = true;
      }, 25);
    }
  }

  onMessageBlur() {
    if (this.isFocused) {
      this.isFocused = false;
    }
  }

  onMessageSend() {
    const userInput = this.message.trim();
    if (userInput) {
      this.sendMessage.emit(userInput);
      this.message = '';
      setTimeout(() => this.messageInput.setFocus(), 50);
    }
  }

  onEnterKeyPress(event: KeyboardEvent) {
    event.preventDefault();
    this.onMessageSend();
  }
}
