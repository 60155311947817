/* eslint-disable @typescript-eslint/naming-convention */
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment.prod';
import {Auth} from './auth.service';


@Injectable({
    providedIn: 'root'
})
export class PortfolioService {
    apiUrl: string = environment.apiUrl;
    token: string = localStorage.getItem('token') ? localStorage.getItem('token') : 'WCFVK9AnWMomOuqQ2Gz5eDswaWOiU8';

    httpOptionsJson = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: `Api-Key ${this.token}`, })
    };

    httpOptionsMultiPart = {
      headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data', Authorization: `Api-Key ${this.token}`, })
    };

    httpOptionsTokenOnly = {
      headers: new HttpHeaders({ Authorization: `Api-Key ${this.token}`, })
    };

    constructor(private http: HttpClient, private authService: Auth) { }

    getClient() {
      return this.http.get<any>(`${this.apiUrl}clients/client/`, this.httpOptionsTokenOnly);
    }

    uploadStory(data: any) {
      return this.http.post<any>(`${this.apiUrl}api/uploading/story/`, data, this.httpOptionsTokenOnly);
    }

    getSuggestions() {
      return this.http.get<any>(`${this.apiUrl}api/uploading/suggestion/`, this.httpOptionsMultiPart);
    }

    getSuggestionById(id: number) {
      return this.http.patch<any>(`${this.apiUrl}api/uploading/suggestion/${id}/`, {}, this.httpOptionsTokenOnly);
    }

    patchSuggestion(id: number, data: any) {
      return this.http.patch<any>(`${this.apiUrl}api/uploading/suggestion/${id}/`, data, this.httpOptionsTokenOnly);
    }

    getPerfumeById(id: number) {
      return this.http.get<any>(`${this.apiUrl}api/ada/perfume/${id}/`, this.httpOptionsMultiPart);
    }

    getProductCategories() {
      return this.http.get<any>(`${this.apiUrl}api/blends/product-categories/`, this.httpOptionsJson);
    }

    submitPerfumeFeedback(product: number, action_type: string) {
      return this.http.post<any>(
        `${this.apiUrl}clients/perfume-feedback/`,
        { product, action_type },
        this.httpOptionsJson,
      );
    }

    submitPerfumeAppFeedback(feedback: boolean) {
      return this.http.post<any>(`${this.apiUrl}clients/app-feedback/`, { feedback }, this.httpOptionsJson);
    }

    submitAppRate(rate: number, comment: string) {
      return this.http.post<any>(`${this.apiUrl}clients/app-rate/`, { rate, comment }, this.httpOptionsJson);
    }

    getAllIngredients() {
      return this.http.get<any>(`${this.apiUrl}api/ada/note/?limit=1000`, this.httpOptionsMultiPart);
    }

    submitPersonalityFeedback(feedback: boolean = true) {
      return this.http.post<any>(`${this.apiUrl}clients/personality-feedback/`, { feedback }, this.httpOptionsJson);
    }

    createRecipe(suggestionId: number, productCategoryId: number) {
      return this.http.post<any>(
        `${this.apiUrl}api/blends/recipes/`,
        { suggestion: suggestionId, product_category: productCategoryId },
        this.httpOptionsJson,
      );
    }

    updateRecipe(recipeId: number, data: any) {
      return this.http.patch<any>(`${this.apiUrl}api/blends/recipes/${recipeId}/`, data, this.httpOptionsJson);
    }
}
