/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import { Ingredient } from '../../../constants/types';

export interface Client {
  image: string;
  background_image: string;
}

export interface PersonalityDTO {
  scores: PersonalityScores;
  highest_emotion: PersonalityHighestEmotion;
}

export interface ProductCategory {
  id: number;
  name: string;
  description: string;
  image: string;
  active: boolean;
}

interface ProductCategories {
  all: ProductCategory[];
  selected: number;
}

export interface Note {
  pk: number;
  name: string;
  description: string;
  emotions: string;
  image: string;
}

export interface RecipeIngredient {
  id: number;
  note: Note;
  layer: 'top' | 'middle' | 'base';
  drops: number;
}

export interface Recipe {
  id: number;
  suggestion: number;
  product_category: number;
  name: string;
  description: string;
  emotions: string;
  size: number;
  is_saved: boolean;
  ingredients: RecipeIngredient[];
}

export interface PersonalityScores {
  fear: number;
  anger: number;
  anticipation: number;
  trust: number;
  surprise: number;
  positive: number;
  negative: number;
  sadness: number;
  disgust: number;
  joy: number;
}

export interface PersonalityHighestEmotion {
  id: number;
  emotion_name: string;
  personality_name: string;
  personality_name_fr: string;
  personality_description: string;
  personality_description_fr: string;
}

export interface Product {
    pk: number;
    name: string;
    image: string;
    brand: string;
    notes: Note[];
    gender: string;
    eco_friendly: boolean;
    exclusive: boolean;
    affiliate_links: any[];
    clean: boolean;
    collection: string;
    perfume: boolean;
    hair: boolean;
    body: boolean;
    home: boolean;
    oil: boolean;
    fetch_accuracy: number;
}

export interface Suggestion {
  id: number;
  added_notes: number[];
  client: number;
  latitude: string;
  longitude: string;
  matched_perfumes: Product[];
  mode: string[];
  recipe_name: string;
  saved_recipe: boolean;
  selected_notes: number[];
}

@Injectable({
  providedIn: 'root'
})
export class DataService {
  client$: Observable<Client>;
  private clientSubject = new BehaviorSubject<Client>(null);
  private ingredients: any = {
    prepared: [],
    selected: [],
    all: []
  };
  private personality: PersonalityDTO = null;
  private productCategories: ProductCategories = {
    all: [],
    selected: null
  };
  private recipe: Recipe = null;
  private suggestionId: number;
  private suggestion: Suggestion = null;
  private recommendations: Product[] = [];

  constructor() {
    this.client$ = this.clientSubject.asObservable();
  }

  setClient(data: Client) {
    this.clientSubject.next(data);
  }

  setIngredients(data: Ingredient[], type: 'prepared' | 'selected' | 'all') {
    this.ingredients[type] = data;
  }

  getIngredients(type: 'prepared' | 'selected' | 'all'): Ingredient[] {
    return this.ingredients[type];
  }

  setProductCategories(data: ProductCategory[]) {
    this.productCategories.all = data;
  }

  getProductCategories(): ProductCategory[] {
    return this.productCategories.all;
  }

  setSelectedProductCategory(id: number) {
    this.productCategories.selected = id;
  }

  getSelectedProductCategory(): number {
    return this.productCategories.selected;
  }

  setPersonality(data: PersonalityDTO) {
    this.personality = data;
  }

  getPersonality() {
    return this.personality;
  }

  getSuggestion(): Suggestion {
    return this.suggestion;
  }

  getCurrentSuggestionId(): number {
    return this.suggestionId;
  }

  setCurrentSuggestionId(id: number): void {
    this.suggestionId = id;
  }

  setSuggestion(suggestion: Suggestion): void {
    this.suggestion = suggestion;
    this.setCurrentSuggestionId(suggestion.id);
    this.recommendations = suggestion.matched_perfumes;
  }

  getRecommendations(): Product[] {
    return this.recommendations;
  }

  getRecommendationById(id: number): Product {
    return this.recommendations.find((product) => product.pk === id);
  }

  getRecipe() {
    return this.recipe;
  }

  setRecipe(data: Recipe) {
    this.recipe = data;
  }

  clearData() {
    this.ingredients = {
      prepared: [],
      selected: [],
      all: []
    };
    this.personality = null;
    this.recipe = null;
    this.suggestion = null;
    this.suggestionId = null;
    this.recommendations = [];
  }
}
