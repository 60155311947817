import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import AnimateImages from '@its2easy/animate-images';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage implements OnInit {
  // public privacyAccepted: boolean = false;
  // public isRequired: boolean = false;
  public displayAnimation = true;
  public canvasWidth = 470;
  public canvasHeight = 270;
  public timer: any;
  constructor(private router: Router) {}

  ngOnInit() {
    const element = document.getElementById(
      'imageAnimation'
    ) as HTMLCanvasElement;
    const instance = new AnimateImages(element, {
      images: [
        '/assets/animation/sphere/sphere_00000.png',
        '/assets/animation/sphere/sphere_00001.png',
        '/assets/animation/sphere/sphere_00002.png',
        '/assets/animation/sphere/sphere_00003.png',
        '/assets/animation/sphere/sphere_00004.png',
        '/assets/animation/sphere/sphere_00005.png',
        '/assets/animation/sphere/sphere_00006.png',
        '/assets/animation/sphere/sphere_00007.png',
        '/assets/animation/sphere/sphere_00008.png',
        '/assets/animation/sphere/sphere_00009.png',
        '/assets/animation/sphere/sphere_00010.png',
        '/assets/animation/sphere/sphere_00011.png',
        '/assets/animation/sphere/sphere_00012.png',
        '/assets/animation/sphere/sphere_00013.png',
        '/assets/animation/sphere/sphere_00014.png',
        '/assets/animation/sphere/sphere_00015.png',
        '/assets/animation/sphere/sphere_00016.png',
        '/assets/animation/sphere/sphere_00017.png',
        '/assets/animation/sphere/sphere_00018.png',
        '/assets/animation/sphere/sphere_00019.png',
        '/assets/animation/sphere/sphere_00020.png',
        '/assets/animation/sphere/sphere_00021.png',
        '/assets/animation/sphere/sphere_00022.png',
        '/assets/animation/sphere/sphere_00023.png',
        '/assets/animation/sphere/sphere_00024.png',
        '/assets/animation/sphere/sphere_00025.png',
        '/assets/animation/sphere/sphere_00026.png',
        '/assets/animation/sphere/sphere_00027.png',
        '/assets/animation/sphere/sphere_00028.png',
        '/assets/animation/sphere/sphere_00029.png',
        '/assets/animation/sphere/sphere_00030.png',
        '/assets/animation/sphere/sphere_00031.png',
        '/assets/animation/sphere/sphere_00032.png',
        '/assets/animation/sphere/sphere_00033.png',
        '/assets/animation/sphere/sphere_00034.png',
        '/assets/animation/sphere/sphere_00035.png',
        '/assets/animation/sphere/sphere_00036.png',
        '/assets/animation/sphere/sphere_00037.png',
        '/assets/animation/sphere/sphere_00038.png',
        '/assets/animation/sphere/sphere_00039.png',
        '/assets/animation/sphere/sphere_00040.png',
        '/assets/animation/sphere/sphere_00041.png',
        '/assets/animation/sphere/sphere_00042.png',
        '/assets/animation/sphere/sphere_00043.png',
        '/assets/animation/sphere/sphere_00044.png',
        '/assets/animation/sphere/sphere_00045.png',
        '/assets/animation/sphere/sphere_00046.png',
        '/assets/animation/sphere/sphere_00047.png',
        '/assets/animation/sphere/sphere_00048.png',
        '/assets/animation/sphere/sphere_00049.png',
        '/assets/animation/sphere/sphere_00050.png',
        '/assets/animation/sphere/sphere_00051.png',
        '/assets/animation/sphere/sphere_00052.png',
        '/assets/animation/sphere/sphere_00053.png',
        '/assets/animation/sphere/sphere_00054.png',
        '/assets/animation/sphere/sphere_00055.png',
        '/assets/animation/sphere/sphere_00056.png',
        '/assets/animation/sphere/sphere_00057.png',
        '/assets/animation/sphere/sphere_00058.png',
        '/assets/animation/sphere/sphere_00059.png',
        '/assets/animation/sphere/sphere_00060.png',
        '/assets/animation/sphere/sphere_00061.png',
        '/assets/animation/sphere/sphere_00062.png',
        '/assets/animation/sphere/sphere_00063.png',
        '/assets/animation/sphere/sphere_00064.png',
        '/assets/animation/sphere/sphere_00065.png',
        '/assets/animation/sphere/sphere_00066.png',
        '/assets/animation/sphere/sphere_00067.png',
        '/assets/animation/sphere/sphere_00068.png',
        '/assets/animation/sphere/sphere_00069.png',
        '/assets/animation/sphere/sphere_00070.png',
        '/assets/animation/sphere/sphere_00071.png',
        '/assets/animation/sphere/sphere_00072.png',
        '/assets/animation/sphere/sphere_00073.png',
        '/assets/animation/sphere/sphere_00074.png',
        '/assets/animation/sphere/sphere_00075.png',
        '/assets/animation/sphere/sphere_00076.png',
        '/assets/animation/sphere/sphere_00077.png',
        '/assets/animation/sphere/sphere_00078.png',
        '/assets/animation/sphere/sphere_00079.png',
        '/assets/animation/sphere/sphere_00080.png',
        '/assets/animation/sphere/sphere_00081.png',
        '/assets/animation/sphere/sphere_00082.png',
        '/assets/animation/sphere/sphere_00083.png',
        '/assets/animation/sphere/sphere_00084.png',
        '/assets/animation/sphere/sphere_00085.png',
        '/assets/animation/sphere/sphere_00086.png',
        '/assets/animation/sphere/sphere_00087.png',
        '/assets/animation/sphere/sphere_00088.png',
        '/assets/animation/sphere/sphere_00089.png',
        '/assets/animation/sphere/sphere_00090.png',
        '/assets/animation/sphere/sphere_00091.png',
        '/assets/animation/sphere/sphere_00092.png',
        '/assets/animation/sphere/sphere_00093.png',
        '/assets/animation/sphere/sphere_00094.png',
        '/assets/animation/sphere/sphere_00095.png',
        '/assets/animation/sphere/sphere_00096.png',
        '/assets/animation/sphere/sphere_00097.png',
        '/assets/animation/sphere/sphere_00098.png',
        '/assets/animation/sphere/sphere_00099.png',
        '/assets/animation/sphere/sphere_00100.png',
        '/assets/animation/sphere/sphere_00101.png',
        '/assets/animation/sphere/sphere_00102.png',
        '/assets/animation/sphere/sphere_00103.png',
        '/assets/animation/sphere/sphere_00104.png',
        '/assets/animation/sphere/sphere_00105.png',
        '/assets/animation/sphere/sphere_00106.png',
        '/assets/animation/sphere/sphere_00107.png',
        '/assets/animation/sphere/sphere_00108.png',
        '/assets/animation/sphere/sphere_00109.png',
        '/assets/animation/sphere/sphere_00110.png',
        '/assets/animation/sphere/sphere_00111.png',
        '/assets/animation/sphere/sphere_00112.png',
        '/assets/animation/sphere/sphere_00113.png',
        '/assets/animation/sphere/sphere_00114.png',
        '/assets/animation/sphere/sphere_00115.png',
        '/assets/animation/sphere/sphere_00116.png',
        '/assets/animation/sphere/sphere_00117.png',
        '/assets/animation/sphere/sphere_00118.png',
        '/assets/animation/sphere/sphere_00119.png',
        '/assets/animation/sphere/sphere_00120.png',
      ] /* required */,
      preload: 'partial',
      preloadNumber: 20,
      loop: true,
      fps: 20,
      poster: '/assets/animation/sphere/sphere_00000.png',
    });
    instance.play();
  }

  ionViewWillEnter() {
    this.startTimer();
    window.addEventListener('click', () => this.resetTimer());
    window.addEventListener('mousemove', () => this.resetTimer());
    window.addEventListener('keypress', () => this.resetTimer());
  }

  startTimer() {
    this.timer = setInterval(() => {
      document.getElementById('animation-box').classList.remove('animate');
      setTimeout(() => {
        document.getElementById('animation-box').classList.add('animate');
      }, 100);
    }, 24000);
  }

  resetTimer() {
    clearInterval(this.timer);
    this.startTimer();
  }

  validateRequired(route) {
    // if (!this.privacyAccepted) {
    //   this.isRequired = true
    //   return;
    // }
    this.router.navigate([route]);
  }

  getStarted(){
    this.router.navigate(['/get-started']);
  }

  // updateIsRequired(value) {
  //   this.isRequired = !this.privacyAccepted
  // }
}
