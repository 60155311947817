import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appTyping]',
})
export class TypingDirective {
  @Output() typingStarted = new EventEmitter<void>();
  @Output() typingStopped = new EventEmitter<void>();

  private inactivityTime = 500; // time in ms of inactivity considered as stopped typing
  private typing = false;
  private timeout: any;

  @HostListener('input', ['$event'])
  onInput() {
    if (!this.typing) {
      this.typing = true;
      this.typingStarted.emit();
    }

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.typing = false;
      this.typingStopped.emit();
    }, this.inactivityTime);
  }
}
